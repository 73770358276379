export const SM_APP_CONFIG = {
  REACT_APP_API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  REACT_APP_OCR_API_ENDPOINT: process.env.REACT_APP_OCR_API_ENDPOINT,
  REACT_APP_AI_API_ENDPOINT: process.env.REACT_APP_AI_API_ENDPOINT,
  REACT_APP_WORKER_API_ENDPOINT: process.env.REACT_APP_WORKER_API_ENDPOINT,
  REACT_APP_AWS_COGNITO_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  REACT_APP_FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  REACT_APP_SIGNUP_DOMAIN_URL: process.env.REACT_APP_SIGNUP_DOMAIN_URL,
  REACT_APP_THEME: process.env.REACT_APP_THEME,
  REACT_APP_AWS_BUCKET_PUBLIC: process.env.REACT_APP_AWS_BUCKET_PUBLIC,
  REACT_APP_AWS_REGION: process.env.REACT_APP_AWS_REGION,
  REACT_APP_HELLOSIGN_TEST_MODE: process.env.REACT_APP_HELLOSIGN_TEST_MODE,
  REACT_APP_HELLOSIGN_CLIENT_ID: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
  REACT_APP_ENV_NAME: process.env.REACT_APP_ENV_NAME,
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_DATADOG_ENABLED: process.env.REACT_APP_DATADOG_ENABLED,
  REACT_APP_DATADOG_SAMPLE_RATE: process.env.REACT_APP_DATADOG_SAMPLE_RATE,
  REACT_APP_DATADOG_APPLICATION_ID: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_CONSOLE_TOAST: process.env.REACT_APP_CONSOLE_TOAST,
  REACT_APP_PREVENT_DOC_TYPE_MANAGEMENT_EDIT: process.env.REACT_APP_PREVENT_DOC_TYPE_MANAGEMENT_EDIT,
  REACT_APP_OCR_SUPPORTED_FILE_TYPES: process.env.REACT_APP_OCR_SUPPORTED_FILE_TYPES,
  REACT_APP_TM_URL: process.env.REACT_APP_TM_URL,
};
